@font-face {
  font-family: "Bebas Neue";
  font-weight: 400;
  src: url(./fonts/BebasNeueRegular.ttf);
}

@font-face {
  font-family: "RF Dewi Expanded";
  font-weight: 900;
  src: url(./fonts/RFDewiExpanded-Black.ttf);
}

@font-face {
  font-family: "RF Dewi Expanded";
  font-weight: 700;
  src: url(./fonts/RFDewiExpanded-Bold.ttf);
}

@font-face {
  font-family: "RF Dewi Expanded";
  font-weight: 600;
  src: url(./fonts/RFDewiExpanded-Semibold.ttf);
}

@font-face {
  font-family: "RF Dewi Expanded";
  font-weight: 400;
  src: url(./fonts/RFDewiExpanded-Regular.ttf);
}

@font-face {
  font-family: "RF Dewi";
  font-weight: 600;
  src: url(./fonts/RFDewi-Semibold.ttf);
}

@font-face {
  font-family: "RF Dewi";
  font-weight: 400;
  src: url(./fonts/RFDewi-Regular.ttf);
}

@font-face {
  font-family: "RF Dewi";
  font-weight: 300;
  src: url(./fonts/RFDewi-Light.ttf);
}
