.privacy {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - var(--containerOffset) * 2);
  height: 70vh;
  color: black;
  background: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  font-family: var(--text-ff);
  border-radius: 20px;
  overflow: hidden;
}

.privacy__inner {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: #0e0e0e;
}

.privacy__content {
  padding: 20px 80px;
  color: var(--white);
}

.privacy__inner h2 {
  font-weight: 700;
  font-size: 2rem;
  margin: 1rem 0;
  font-family: var(--main-ff);
  color: var(--olive);
}

.privacy__inner h3 {
  font-weight: 700;
  font-size: 1.1rem;
  margin: 0.5rem 0;
  font-family: var(--main-ff);
  color: var(--olive);
}

.privacy__title__container {
  margin-top: -1px;
  background: black;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
}

.privacy__title {
  color: var(--blue);
  padding: 20px 80px;
}

.privacy__inner::-webkit-scrollbar {
  width: 15px;
}

.privacy__inner::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.privacy__inner::-webkit-scrollbar-thumb {
  background-color: #888888;
}

.privacy__inner::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}

.privacy__inner ol {
  padding-left: 40px;
}

.privacy__inner ul {
  padding-left: 50px;
}

.privacy__inner ol li p {
  padding-left: 0;
}

.privacy__inner p {
  line-height: 160%;
}

.privacy__inner__cross {
  position: fixed;
  top: 20px;
  right: 35px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.privacy__inner__cross__line {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  background: var(--white);
}

.privacy__inner__cross__line:nth-child(1) {
  transform: rotate(45deg) translateX(9px) translateY(9px);
}

.privacy__inner__cross__line:nth-child(2) {
  transform: rotate(-45deg) translateX(9px) translateY(-9px);
}

@media (max-width: 600px) {
  .privacy__inner::-webkit-scrollbar {
    width: 10px;
  }
  .privacy__content {
    padding: 10px 20px;
  }
  .privacy__title__container {
    height: 100px;
  }
  .privacy__title {
    padding: 10px 20px;
  }
  .privacy__inner {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 0.9rem;
  }

  .privacy__inner ol {
    padding-left: 20px;
  }

  .privacy__inner p {
    padding-left: 5px;
  }

  .privacy__inner__cross {
    right: 20px;
    top: 10px;
    width: 20px;
  }

  .privacy__inner ul {
    padding-left: 20px;
  }

  .privacy__inner h2 {
    font-weight: 600;
    font-size: 1rem;
    padding-right: 40px;
  }

  .privacy__inner h1 {
    font-weight: 600;
    font-size: 1.3rem;
    padding-right: 10px;
  }
}
